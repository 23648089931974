<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-hummer"> </i> 功能室 | 统计报告</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-date-picker v-model="date.range" @change="getList" type="daterange" value-format="yyyy-MM-dd"
                              range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                              style="margin-left: 5px"/>
            </div>
            <div class="datas">
              <div class="table">
                <el-table :data="list" stripe border :cell-style="{padding:'0px'}"
                          :header-cell-style="{borderBottom:'solid 1px #ccc',backgroundColor:'#f5f5f5',textAlign:'center',padding:'5px 0px'}">
                  <el-table-column prop="Func_Room_Name" label="功能室名称"  align="center"/>
                  <el-table-column prop="RoomUseCount" label="使用次数" width="120" align="center"/>
                  <el-table-column prop="AllRoomUseCount" label="总次数" width="120" align="center"/>
                </el-table>
              </div>

              <div class="chart" id="chart" style="width: 300px;height: 300px"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
export default {
  name: "index",
  data(){
    return{
      date: {range: []},
      list:[],
      loading:false,
    }
  },
  created() {
    this.getList();
  },
  methods:{
    getList(){
      let self = this;
      let n = this.date.range.length
      if (n > 0) {
        var Dts = this.date.range[0]
        var Dte = this.date.range[1]
      }
      this.loading = true;
      this.whale.remote.getResult({
        url: "/api/School/FUNC/FuncRoomUse/GetRoomUseData",
        data: {Dts,Dte},
        completed: function (its) {
          self.list = its.DATA;
          self.loading = false;
          self.getChart(its.DATA)
        }
      })
    },
    getChart(data){
      var chartDom = document.getElementById('chart');
      var myChart = this.$echarts.init(chartDom);
      var option;
      let arr = data.reduce((prev,cur)=>{
        prev.push({
          value:cur.RoomUseCount,
          name:cur.Func_Room_Name
        })
        return prev
      },[])
      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['60%', '40%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: arr
          }
        ]
      };

      option && myChart.setOption(option);
    },
  }
}
</script>

<style scoped>
.datas{
  margin-top: 10px;
  display: flex;

}
.table{
  flex: 1;
}
.chart{
  flex: 1;
}
</style>